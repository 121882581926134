import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	Modal,
	Radio,
	Row,
	Select,
	Spin,
	Switch,
	message,
} from 'antd'

// import { accountDeletionReasonService } from "../../redux/services";
import { UIDrawerForm } from '../../components'
import MaskedInput from 'react-text-mask'
import { PatternFormat, NumericFormat } from 'react-number-format'
import { franchiseeService } from 'redux/services'
import moment from 'moment'
import axios from 'axios'
import { unitService } from 'redux/services'
import { API_ERRO_TYPE_CANCEL } from 'config/general'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,
			isSending: false,
			documentType: 'cpf',

			unitsLoading: false,
			units: [],
			commission: 0
		}

		this._axiosCancelToken = null
	}

	fetchUnits = (value) => {
		if (this._axiosCancelToken) {
			this._axiosCancelToken.cancel('Only one request allowed at a time.')
		}

		this._axiosCancelToken = axios.CancelToken.source()

		if (!value.trim().length) {
			this.setState({
				unitsLoading: false,
				units: [],
			})

			return false
		}

		this.setState({
			unitsLoading: true,
		})

		unitService
			.getAll({
				search: value,
				cancelToken: this._axiosCancelToken.token,
			})
			.then((response) => {
				this.setState({
					unitsLoading: false,
					units: response.data.data,
				})
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null

				this.setState({
					unitsLoading: false,
				})

				Modal.error({
					title: 'Ocorreu um erro!',
					content: String(data),
				})
			})
	}

	onOpen = () => {
		this.setState({
			isLoading: false,
		})
	}

	resetFields = () => {
		this.form.resetFields()
	}

	onClose = () => {
		// Reset fields
		this.resetFields()

		// Callback
		this.props.onClose()
	}

	onFinish = (values) => {
		this.setState({
			isSending: true,
		})

		const data = {
			...values,
			commission: this.state.commission,
			birth: moment(values.birth).format('YYYY-MM-DD'),
		}

		franchiseeService
			.create(data)
			.then((response) => {
				this.setState({
					isSending: false,
				})

				// Reset fields
				this.resetFields()

				// Success message
				message.success('Registro cadastrado com sucesso.')

				// Callback
				this.props.onComplete()
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				})

				Modal.error({
					title: 'Ocorreu um erro!',
					content: String(data),
				})
			})
	}

	render() {
		const { visible } = this.props

		const { isLoading, isSending, documentType, unitsLoading, units } =
			this.state

		const documentMask =
			documentType === 'cpf' ? '###.###.###-##' : '##.###.###/####-##'

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Cadastrar novo franqueado"
			>
				<Form
					ref={(el) => (this.form = el)}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						password_random: true,
						is_active: true,
						email: "",
						password: ""
					}}
					autoComplete={false}
				>
					<Row gutter={16}>
						<Col xl={24} sm={24}>
							<Form.Item
								name="name"
								label="Nome"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col xl={12} sm={12}>
							<Form.Item
								name="company_name"
								label="Razão social"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xl={12} sm={12}>
							<Form.Item
								name="unit"
								label="Unidade"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Select
									filterOption={false}
									allowClear
									placeholder="Pesquise a unidade"
									notFoundContent={
										unitsLoading ? (
											<Spin
												indicator={
													<i className="fad fa-spinner-third fa-spin" />
												}
											/>
										) : null
									}
									onSearch={this.fetchUnits}
									showSearch
								>
									{units.map((item, index) => (
										<Select.Option
											key={index}
											value={item.id}
										>
											{item.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col xl={12} sm={12}>
							<Form.Item
								label="Tipo de documento"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Radio.Group
									defaultValue={documentType}
									options={[
										{
											label: 'CNPJ',
											value: 'cnpj',
										},
										{
											label: 'CPF',
											value: 'cpf',
										},
									]}
									onChange={(e) =>
										this.setState({
											documentType: e.target.value,
										})
									}
								/>
							</Form.Item>
						</Col>
						<Col xl={12} sm={12}>
							<Form.Item
								name="document"
								label="Documento"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<PatternFormat
									className="ant-input"
									format={documentMask}
									allowEmptyFormatting
									mask="_"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xl={12} sm={12}>
							<Form.Item
								name="phone"
								label="Telefone"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<PatternFormat
									className="ant-input"
									format="(##) #####-####"
									allowEmptyFormatting
									mask="_"
								/>
							</Form.Item>
						</Col>
						<Col xl={12} sm={12}>
							<Form.Item
								name="birth"
								label="Nascimento"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<DatePicker
									style={{ width: '100%' }}
									format="DD/MM/YYYY"
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col xl={12} sm={12}>
							<Form.Item
								name="email"
								label="E-mail"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Input type="email" autoComplete="nope" />
							</Form.Item>
						</Col>
						<Col xl={12} sm={12}>
							<Form.Item
								name="password"
								label="Senha"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Input.Password autoComplete='new-password' />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xl={12} sm={12}>
							<Form.Item
								label="Comissão"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<NumericFormat
									className="ant-input"
									suffix='%'
									thousandSeparator
									valueIsNumericString={true}
									value={this.state.commission}
									onValueChange={({ floatValue }) => {
										this.setState({
											commission: floatValue
										})
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Divider />
					<Form.Item
						name="is_active"
						label="Ativo"
						valuePropName="checked"
					>
						<Switch />
					</Form.Item>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Divider, Form, Input, Modal, Row, Select, Switch, message } from 'antd'

// import { accountDeletionReasonService } from "../../redux/services";
import { UIDrawerForm, UIInputMask } from '../../components'
import { customerService } from 'redux/services'
import MaskedInput from 'react-text-mask'
import { PatternFormat } from 'react-number-format'
import { taxRegimeService } from 'redux/services'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,
			isSending: false,
			taxRegimes: []
		}
	}

	onOpen = () => {
		taxRegimeService.getAll()
			.then((response) => {
				this.setState({
					taxRegimes: response.data.data,
					isLoading: false,
				})
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			})
	}

	resetFields = () => {
		this.form.resetFields()
	}

	onClose = () => {
		// Reset fields
		this.resetFields()

		// Callback
		this.props.onClose()
	}

	onFinish = (values) => {
		this.setState({
			isSending: true,
		})

		const data = { ...values }

		customerService.create(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro cadastrado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}

	onSearch = (value) => {
		customerService
			.consultCnpj({ cnpj: value })
			.then((response) => {
				const { data } = response

				this.form.setFieldsValue({
					company_name: data.estabelecimento?.nome_fantasia ?? data.razao_social,
					corporate_name: data.razao_social,
					contact_name: '',
					phone:
						data.estabelecimento?.ddd1 +
						data.estabelecimento?.telefone1,
					email: data.estabelecimento?.email,
				})
			})
			.catch((data) => {
				Modal.error({
					title: 'Ocorreu um erro!',
					content: String(data),
				})
			})
	}

	render() {
		const { visible } = this.props

		const { isLoading, isSending, taxRegimes } = this.state

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Inserir novo registro"
			>
				<Form
					ref={(el) => (this.form = el)}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						password_random: true,
						is_active: true,
					}}
				>
					<Row gutter={16}>
						<Col xl={24} sm={24}>
							<Form.Item
								name="document"
								label="CNPJ"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<UIInputMask
									mask="00.000.000/0000-00"
									onSearch={this.onSearch}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col xl={24} sm={24}>
							<Form.Item
								name="company_name"
								label="Nome da empresa"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col xl={12} sm={12}>
							<Form.Item
								name="corporate_name"
								label="Razão social"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xl={12} sm={12}>
							<Form.Item
								name="contact_name"
								label="Nome do responsável"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col xl={12} sm={12}>
							<Form.Item
								name="phone"
								label="Telefone"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<PatternFormat
									className="ant-input"
									format="(##) #####-####"
									allowEmptyFormatting
									mask="_"
								/>
							</Form.Item>
						</Col>
						<Col xl={12} sm={12}>
							<Form.Item
								name="email"
								label="E-mail"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
									{
										type: 'email',
									},
								]}
							>
								<Input type="email" />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col xl={12} sm={12}>
							<Form.Item
								name="tax_regime"
								label="Regime fiscal"
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Select
									filterOption={false}
									allowClear
									placeholder="Selecione o regime fiscal"
								>
									{taxRegimes.map((item, index) => (
										<Select.Option
											key={index}
											value={item.id}
										>
											{item.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xl={12} sm={12}>
							<Form.Item
								name="blind_company"
								label="Deseja blindar cliente?"
								valuePropName="checked"
							>
								<Switch />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col xl={24} sm={24}>
							<Form.Item
								name="obs"
								label="Observação"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório.',
									},
								]}
							>
								<Input.TextArea />
							</Form.Item>
						</Col>
					</Row>

					<Divider />
					<Form.Item
						name="is_active"
						label="Ativo"
						valuePropName="checked"
					>
						<Switch />
					</Form.Item>
				</Form>
				<Modal open={this.state.iFrame}>
					<iframe src={this.state.iFrame} />
				</Modal>
			</UIDrawerForm>
		)
	}
}

export default Create

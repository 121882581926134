import React, { Component } from 'react'
import axios from 'axios'
import * as PropTypes from 'prop-types'
import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
} from 'antd'

import { unitService } from '../../redux/services'

import { UIDrawerForm } from '../../components'
import { PatternFormat } from 'react-number-format'
import moment from 'moment'
import MaskedInput from 'react-text-mask'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Edit extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isSending: false,
            id: 0,
            isSendingZipcode: false,
        }

        this._axiosCancelToken = null
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
        })

        unitService
            .show({ id })
            .then((response) => {
                let item = response.data

                this.setState(
                    {
                        isLoading: false,
                    },
                    () => {
                        // Fill form
                        this.fillForm(item)
                    }
                )
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                    onOk: () => {
                        // Force close
                        return this.onClose()
                    },
                })
            })
    }

    fillForm = (data) => {
        this.form.setFieldsValue({
            name: data.name,
            email: data.email,
            phone: data.phone,
            zipcode: data.zipcode,
            street: data.street,
            district: data.district,
            number: data.number,
            city: data.city,
            state: data.state,
        })
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const { id } = this.state

        const data = {
            ...values,
        }

        // id
        data.id = id

        unitService
            .edit(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro atualizado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    zipcodeKeyUp = (e) => {
        // Ctrl + A, Ctrl + C, 9 tab, 16 shift, 18 alt, 17 Ctrl, 37 left, 39 right, 38 up, 40 down
        if ((e.ctrlKey && e.keyCode === 65) || (e.ctrlKey && e.keyCode === 67) || e.keyCode === 9 || e.keyCode === 16 || e.keyCode === 18 || e.keyCode === 17 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 38 || e.keyCode === 40) {
            return false;
        }

        let zipcode = e.currentTarget.value.replace(/[^0-9]/g, "");

        if (zipcode.length < 8) {
            return false;
        }

        this.setState({
            isSendingZipcode: true,
        });

        webserviceService.findZipcode({
            zipcode: zipcode
        })
            .then((response) => {
                this.setState({
                    isSendingZipcode: false,
                }, () => {
                    this.form.setFieldsValue({
                        street: response.data.street,
                        district: response.data.district,
                        state: response.data.state,
                        city: response.data.city,
                    });
                });
            })
            .catch((data) => {
                this.setState({
                    isSendingZipcode: false,
                });
            });
    };

    render() {
        const { visible } = this.props

        const { id, isLoading, isSending, isSendingZipcode } = this.state


        return (
            <UIDrawerForm
                visible={visible}
                width={700}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title={`Editar registro [${id}]`}
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                    initialValues={{
                        password_random: true,
                        is_active: true,
                    }}
                >
                    <Row gutter={16}>
                        <Col xl={24} sm={24}>
                            <Form.Item name="name" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item name="email" label="E-mail" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
                                <Input type="email" />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item name="phone" label="Telefone" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
                                <PatternFormat className="ant-input" format="(##) #####-####" allowEmptyFormatting mask="_" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={8}>
                            <Form.Item name="zipcode" label="CEP">
                                <MaskedInput
                                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                                    className="ant-input"
                                    onKeyUp={this.zipcodeKeyUp}
                                />
                            </Form.Item>
                            {isSendingZipcode && <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} style={{ position: "absolute", top: "37px", left: "100%" }} />}
                        </Col>
                        <Col xs={24} sm={16} xl={16}>
                            <Form.Item name="street" label="Endereço" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item name="district" label="Bairro" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item name="number" label="Número" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xl={12} sm={12}>
                            <Form.Item name="city" label="Cidade" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xl={12} sm={12}>
                            <Form.Item name="state" label="Estado" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Edit

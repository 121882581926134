import React, { Component } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { dashboardService } from "redux/services";
import ApexCharts from 'apexcharts'
import { Modal } from "antd";

class Dashboard extends Component {
	constructor(props) {
		super(props)


		this.state = {
			isLoading: true,
			data: {},
			series: [
				{
					name: "",
					data: [200, 330, 548, 740, 880, 990, 1100, 1380],
				},
			],
			options: {
				chart: {
					type: 'bar',
					height: 350,
				},
				plotOptions: {
					bar: {
						borderRadius: 0,
						horizontal: true,
						distributed: true,
						barHeight: '80%',
						isFunnel: true,
					},
				},
				colors: [
					'#F44F5E',
					'#E55A89',
					'#D863B1',
					'#CA6CD8',
					'#B57BED',
					'#8D95EB',
					'#62ACEA',
					'#4BC3E6',
				],
				dataLabels: {
					enabled: true,
					formatter: function (val, opt) {
						return opt.w.globals.labels[opt.dataPointIndex]
					},
					dropShadow: {
						enabled: true,
					},
				},
				title: {
					text: 'Pyramid Chart',
					align: 'middle',
				},
				xaxis: {
					categories: ['Sweets', 'Processed Foods', 'Healthy Fats', 'Meat', 'Beans & Legumes', 'Dairy', 'Fruits & Vegetables', 'Grains'],
				},
				legend: {
					show: false,
				},
			}
		}
	}


	componentDidMount() {
		this.fetchInitial()
	}

	fetchInitial = () => {
		this.setState({
			isLoading: true
		})

		dashboardService.getAll()
			.then((response) => {
				this.setState({
					isLoading: false,
					data: response.data
				})
			})
			.catch((data) => {
				this.setState({
					isLoading: false
				}, () => {
					Modal.error({
						title: "Ocorreu um erro!",
						content: String(data),
					});
				})
			})
	}


	render() {
		const { data, isLoading } = this.state

		return (
			<DashboardLayout>
				<DashboardNavbar title="Dashboard" />
				<MDBox py={3}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="dark"
									icon="people"
									title="Clientes"
									count={data.data?.customers}
									percentage={{
										color: data.percentageChange?.customers > 0 ? "success" : "error",
										amount: `${data.percentageChange?.customers}%`,
										label: "no ultimo mês",
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									icon="leaderboard"
									title="Franqueados"
									count={data.data?.franchisees}
									percentage={{
										color: data.percentageChange?.franchisees > 0 ? "success" : "error",
										amount: `${data.percentageChange?.franchisees}%`,
										label: "no ultimo mês",
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="success"
									icon="store"
									title="Vendas"
									count="34k"
									percentage={{
										color: "success",
										amount: "+1%",
										label: "no ultimo mês",
									}}
								/>
							</MDBox>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						{/* <div id="chart">
							<ApexCharts options={this.state.options} series={this.state.series} type="bar" height={350} />
						</div> */}
					</Grid>
					{/*<Grid item xs={12} md={6} lg={3}>*/}
					{/*	<MDBox mb={1.5}>*/}
					{/*		<ComplexStatisticsCard*/}
					{/*			color="primary"*/}
					{/*			icon="person_add"*/}
					{/*			title="Followers"*/}
					{/*			count="+91"*/}
					{/*			percentage={{*/}
					{/*				color : "success",*/}
					{/*				amount: "",*/}
					{/*				label : "Just updated",*/}
					{/*			}}*/}
					{/*		/>*/}
					{/*	</MDBox>*/}
					{/*</Grid>*/}
					{/*<MDBox mt={4.5}>*/}
					{/*	<Grid container spacing={3}>*/}
					{/*		<Grid item xs={12} md={6} lg={4}>*/}
					{/*			<MDBox mb={3}>*/}
					{/*				<ReportsBarChart*/}
					{/*					color="info"*/}
					{/*					title="website views"*/}
					{/*					description="Last Campaign Performance"*/}
					{/*					date="campaign sent 2 days ago"*/}
					{/*					chart={reportsBarChartData}*/}
					{/*				/>*/}
					{/*			</MDBox>*/}
					{/*		</Grid>*/}
					{/*		<Grid item xs={12} md={6} lg={4}>*/}
					{/*			<MDBox mb={3}>*/}
					{/*				<ReportsLineChart*/}
					{/*					color="success"*/}
					{/*					title="daily sales"*/}
					{/*					description={*/}
					{/*						<>*/}
					{/*							(<strong>+15%</strong>) increase in today sales.*/}
					{/*						</>*/}
					{/*					}*/}
					{/*					date="updated 4 min ago"*/}
					{/*					chart={sales}*/}
					{/*				/>*/}
					{/*			</MDBox>*/}
					{/*		</Grid>*/}
					{/*		<Grid item xs={12} md={6} lg={4}>*/}
					{/*			<MDBox mb={3}>*/}
					{/*				<ReportsLineChart*/}
					{/*					color="dark"*/}
					{/*					title="completed tasks"*/}
					{/*					description="Last Campaign Performance"*/}
					{/*					date="just updated"*/}
					{/*					chart={tasks}*/}
					{/*				/>*/}
					{/*			</MDBox>*/}
					{/*		</Grid>*/}
					{/*	</Grid>*/}
					{/*</MDBox>*/}
					{/*<MDBox>*/}
					{/*	<Grid container spacing={3}>*/}
					{/*		<Grid item xs={12} md={6} lg={8}>*/}
					{/*			<Projects />*/}
					{/*		</Grid>*/}
					{/*		<Grid item xs={12} md={6} lg={4}>*/}
					{/*			<OrdersOverview />*/}
					{/*		</Grid>*/}
					{/*	</Grid>*/}
					{/*</MDBox>*/}
				</MDBox>
			</DashboardLayout>
		)
	}
}

export default Dashboard;

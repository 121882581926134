import React, { Component } from "react"

import { UILayout } from '../../components'
import { Button, Dropdown, Menu, Modal } from "antd";
import { connect } from "react-redux";
import { generalActions } from "../../redux/actions";
import { Link } from "react-router-dom";
import { franchiseeService } from "../../redux/services";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { compose } from "redux";

const config = {
	title: "Detalhes do franqueado",
	permissionPrefix: "franchisees",
	list: "franchisee",
	searchPlaceholder: "Buscar por nome",
	orders: [
		{
			label: "Mais recentes",
			field: "id",
			sort: "desc",
			default: true,
		},
		{
			label: "Mais antigos",
			field: "id",
			sort: "asc",
		},
	],
};

class FranchiseeShow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: []
		}

		const defaultOrder = config.orders.find(o => o.default);

		this.state = {
			isLoading: false,
			listType: "list",
			data: [],
			pagination: {
				current: 1,
				pageSize: 20,
				total: 0,
			},
			orderByLabel: defaultOrder.label,
			orderByField: defaultOrder.field,
			orderBySort: defaultOrder.sort,
			search: "",
			client_name: "",
			// Actions
			showModalVisible: false,
			exportModalVisible: false,
			filtersModalVisible: false,
			activeLoadings: [],
			isExporting: false,
			// Filters
			totalFilters: 0,
			filters: {
				is_active: null,
				created_at: null,
				client_id: null,
				property_id: null,
				cultivation_id: null,
			},
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.listType && state.listType !== props.listType) {
			return {
				listType: props.listType
			};
		}

		return null;
	}

	componentDidMount() {
		// Fecth all
		this.fetchGetAll(true);
	}

	menuItem = (item) => (
		<Menu className="actions-dropdown-menu">
			<Menu.Item key="show">
				<a onClick={() => this.showOpen(item)}>
					<i className="fal fa-file" />Visualizar
				</a>
			</Menu.Item>
			<Menu.Item key="delete" className="divider btn-delete">
				<a onClick={() => this.deleteConfirm(item)}>
					<i className="fal fa-trash" />Excluir
				</a>
			</Menu.Item>
		</Menu>
	);

	columns = () => {
		const listTypeCard = this.state.listType === "card";

		return [
			{
				title: "ID",
				className: "id",
				visible: !listTypeCard,
				width: 50,
				render: (item) => <span title={item.id}>{item.id}</span>,
			},
			{
				title: "Empresa",
				render: (item) => <Link to={`/franqueados/${item.id}`}>{item.company_name}</Link>,
			},
			{
				title: "CPF/CNPJ",
				render: (item) => <span title={item.document}>{item.document}</span>,
			},
			{
				title: "Responsável",
				width: 222,
				render: (item) => <span title={item.name}>{item.name}</span>,
			},
			{
				title: "E-mail",
				width: 222,
				render: (item) => <span title={item.email}>{item.email}</span>,
			},
			{
				title: "Telefone",
				width: 222,
				render: (item) => <Button icon={<i className="fab fa-whatsapp"></i>} type="link" onClick={() => this.onOpenWhatsapp(item.phone)} title={item.phone}>{item.phone}</Button>,
			},
			{
				title: "Ações",
				className: "actions no-ellipsis",
				visible: true,
				render: (item) => (
					<Dropdown menu={this.menuItem(item)} className="actions-dropdown" placement="bottomRight" trigger={["click"]}>
						<Button icon={<i className="fal fa-ellipsis-v" />} />
					</Dropdown>
				),
			},
		];
	};

	fetchGetAll = (init = false, exportItems = false) => {
		const { pagination, orderByField, orderBySort, search, filters, client_name } = this.state;

		if (exportItems) {
			this.setState({
				isExporting: true,
			});
		}
		else {
			this.setState({
				isLoading: true,
			});
		}

		const data = {
			orderBy: `${orderByField}:${orderBySort}`,
			search: search,
			// client_name: client_name,
		};

		if (exportItems) {
			data.exportItems = true;
		}
		else {
			data.page = init ? 1 : pagination.current;
			data.limit = pagination.pageSize;
		}

		if (filters.client_id !== null) {
			data.client_id = filters.client_id;
		}

		if (filters.property_id !== null) {
			data.property_id = filters.property_id;
		}

		if (filters.cultivation_id !== null) {
			data.cultivation_id = filters.cultivation_id;
		}

		if (filters.is_active !== null) {
			data.is_active = filters.is_active;
		}

		if (filters.created_at) {
			data.created_at = [
				filters.created_at[0].clone().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
				filters.created_at[1].clone().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
			];
		}

		franchiseeService.getAll(data)
			.then((response) => {
				if (exportItems) {
					this.setState({
						isExporting: false,
					});

					window.open(response.data.file_url, "_blank");
				}
				else {
					this.setState(state => ({
						isLoading: false,
						data: response.data.data,
						pagination: {
							...state.pagination,
							current: response.data.meta.current_page,
							total: response.data.meta.total,
						},
					}));
				}
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onOpenWhatsapp = (phone) => {
		phone = phone.replace(/\D/g, '');
		return window.open("https://wa.me/+55" + phone, "_blank")
	}

	onListTypeChange = (type) => {
		this.props.onChangeListType(type);
	};

	onPaginationChange = (page) => {
		this.setState(state => ({
			pagination: {
				...state.pagination,
				current: page,
			},
		}), () => {
			this.fetchGetAll();
		});
	};

	onOrderChange = (value) => {
		const defaultOrder = config.orders.find(o => `${o.field}:${o.sort}` === value);

		if (!defaultOrder) return null;

		this.setState(state => ({
			orderByLabel: defaultOrder.label,
			orderByField: defaultOrder.field,
			orderBySort: defaultOrder.sort,
		}), () => {
			this.fetchGetAll(true);
		});
	};

	onSearch = (value) => {
		this.setState({
			client_name: value,
		}, () => {
			this.fetchGetAll(true);
		});
	};

	onSearchChange = (e) => {
		// If it does not have type then it's cleaning
		if (!e.hasOwnProperty("type")) {
			const { search } = this.state;

			this.setState({
				search: e.target.value,
			}, () => {
				if (search) {
					this.fetchGetAll(true);
				}
			});
		}
	};

	/**
	 * Show
	 *
	 * @param uuid
	 */
	showOpen = ({ uuid }) => {
		this.setState({ showModalVisible: true });

		// On open screen
		this.showScreen.onOpen(uuid);
	};

	showOnClose = () => {
		this.setState({ showModalVisible: false });
	};

	/**
	 * Delete
	 *
	 * @param uuid
	 */
	deleteConfirm = ({ uuid }) => {
		Modal.confirm({
			title: "Confirmar exclusão!",
			content: "Tem certeza de que deseja excluir este registro?",
			okText: "Excluir",
			autoFocusButton: null,
			onOk: () => {
				return this.deleteConfirmed(uuid);
			}
		});
	};

	deleteConfirmed = (uuid) => {
		return simulationService.destroy({ uuid })
			.then((response) => {
				// Fecth all
				this.fetchGetAll();
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	/**
	 * Filter
	 */
	filtersOpen = () => {
		this.setState({ filtersModalVisible: true });

		// On open screen
		this.filtersScreen.onOpen({ ...this.state.filters });
	};

	filtersOnClose = () => {
		this.setState({ filtersModalVisible: false });
	};

	filtersOnComplete = (filters) => {
		this.setState({ filtersModalVisible: false });

		this.setState({
			totalFilters: Object.keys(filters).filter(key => filters.hasOwnProperty(key) && filters[key] !== null).length,
			filters: filters,
		}, () => {
			// Fecth all
			this.fetchGetAll(true);
		});
	};

	showCustomers = () => {
		const { navigate, location } = this.props.router
		const userId = location?.state?.userId

		navigate('/clientes-franqueado', { replace: true, state: { userId } });
	}

	render() {
		const { data } = this.state

		return (
			<UILayout>
				<DashboardNavbar absolute isMini title={config.title} />
				<div className="page page-franchisee-show">
					<div className="item-franchisee-button" onClick={this.showCustomers}>
						<i className="fal fa-users"></i>
						<span>Clientes</span>
					</div>

					<div className="item-franchisee-button">
						<i className="far fa-dollar-sign"></i>
						<span>Vendas</span>
					</div>

				</div>
			</UILayout>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
		listType: state.general.listType[config.list],
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChangeListType: (type) => {
			dispatch(generalActions.changeListType(config.list, type));
		}
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(FranchiseeShow)
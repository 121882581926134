import permissions from "./permissions"

const admin = [
	{
		type: "collapse",
		name: "Dashboard",
		key: "dashboard",
		icon: <i class="fas fa-tachometer-alt-slow"></i>,
		path: "/",
	},
	{
		type: "collapse",
		name: "Franqueados",
		key: "franqueados",
		icon: <i class="fas fa-user-tie"></i>,
		path: "/franqueados",
	},
	{
		type: "collapse",
		name: "Colaboradores",
		key: "colaboradores",
		icon: <i class="far fa-users"></i>,
		path: "/colaboradores",
	},
	{
		type: "collapse",
		name: "Clientes",
		key: "clientes",
		icon: <i class="far fa-user-shield"></i>,
		path: "/clientes",
	},
	{
		type: "collapse",
		name: "Serviços",
		key: "servicos",
		icon: <i class="far fa-hand-holding-usd"></i>,
		path: "/servicos",
	},
	{
		type: "collapse",
		name: "Times",
		key: "times",
		icon: <i class="fad fa-users-class"></i>,
		path: "/times",
	},
	{
		type: "collapse",
		name: "Unidades",
		key: "unidades",
		icon: <i class="fad fa-building"></i>,
		path: "/unidades",
	},
	{
		type: "collapse",
		name: "Vendas",
		key: "vendas",
		icon: <i class="fas fa-usd-square"></i>,
		path: "/vendas",
	}
]

const franchisee = [
	{
		type: "collapse",
		name: "Dashboard",
		key: "dashboard",
		icon: <i class="fas fa-tachometer-alt-slow"></i>,
		path: "/",
	},
	{
		type: "collapse",
		name: "Colaboradores",
		key: "colaboradores",
		icon: <i class="far fa-users"></i>,
		path: "/colaboradores",
	},
	{
		type: "collapse",
		name: "Clientes",
		key: "clientes",
		icon: <i class="far fa-user-shield"></i>,
		path: "/clientes",
	},
	{
		type: "collapse",
		name: "Vendas",
		key: "vendas",
		icon: <i class="fas fa-usd-square"></i>,
		path: "/vendas",
	}
]

const consultant = [
	{
		type: "collapse",
		name: "Dashboard",
		key: "dashboard",
		icon: <i class="fas fa-tachometer-alt-slow"></i>,
		path: "/",
	},
	{
		type: "collapse",
		name: "Clientes",
		key: "clientes",
		icon: <i class="far fa-user-shield"></i>,
		path: "/clientes",
	},
	{
		type: "collapse",
		name: "Vendas",
		key: "vendas",
		icon: <i class="fas fa-usd-square"></i>,
		path: "/vendas",
	}
]

const collaborator = [
	{
		type: "collapse",
		name: "Dashboard",
		key: "dashboard",
		icon: <i class="fas fa-tachometer-alt-slow"></i>,
		path: "/",
	},
	{
		type: "collapse",
		name: "Clientes",
		key: "clientes",
		icon: <i class="far fa-user-shield"></i>,
		path: "/clientes",
	},
	{
		type: "collapse",
		name: "Vendas",
		key: "vendas",
		icon: <i class="fas fa-usd-square"></i>,
		path: "/vendas",
	}
]

export default {
	admin,
	franchisee,
	consultant,
	collaborator
}
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Col, Form, Input, Modal, Row, Switch, Tabs, Timeline, message } from "antd";

import moment from "moment";

import { commentService, customerService, franchiseeService, simulationService } from "../../redux/services";

import { UIDrawerForm, } from "../../components";
import { number_format } from "../../helpers/phpjs";

const config = {
    externalName: "Commentário",
};

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Comment extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        external: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            id: 0,
            items: [],
            comment: ""
        }
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
            items: [],
        });

        commentService.show({ id })
            .then((response) => {
                let items = response.data;

                this.setState({
                    isLoading: false,
                    items: items,
                });
            })
            .catch((data) => {
                Modal.error({
                    title: "Ocorreu um erro!",
                    content: String(data),
                    onOk: () => {
                        // Force close
                        return this.onClose();
                    }
                });
            });
    };

    resetFields = () => {
        this.setState({
            comment: ""
        }, () => {
            this.form.resetFields()
        })
    };

    onClose = () => {
        // Reset fields
        this.resetFields();

        // Callback
        this.props.onClose();
    };

    onFinish = (values) => {
        this.setState({
            isSending: true,
        })

        const data = {
            ...values,
            customer_id: this.state.id
        }

        commentService.create(data)
            .then(() => {
                this.setState({
                    isSending: false,
                });

                // Reset fields
                this.resetFields();

                // Success message
                message.success("Registro cadastrado com sucesso.");

                // Callback
                this.props.onComplete();
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                });

                Modal.error({
                    title: "Ocorreu um erro!",
                    content: String(data),
                });
            });
    }

    onChangeComment = (e) => {
        console.log(e)
        this.setState({
            comment: e.target.value
        })
    }

    render() {
        const { visible } = this.props;
        const { id, isLoading, items, comment } = this.state;

        return (
            <UIDrawerForm
                visible={visible}
                width={300}
                onClose={this.onClose}
                isLoading={isLoading}
                showBtnSave={!!comment.length}
                formId={formId}
                title={`Visualizar ${this.props.external ? config.externalName : "registro"} [${id}]`}>
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                    initialValues={{
                        password_random: true,
                        is_active: true,
                    }}
                >
                    <Form.Item
                        name="text"
                        label="Comentário"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Campo obrigatório.',
                            },
                        ]}>
                        <Input.TextArea onChange={this.onChangeComment} />
                    </Form.Item>
                    <Timeline
                        style={{ marginTop: 30 }}
                        mode="left"
                        items={items.map((comment) => ({
                            label: comment.user.name + " - " + moment(comment.created_at).calendar(),
                            children: <span style={{ paddingBottom: 20, display: "block" }}>{comment.text}</span>
                        }))}
                    />
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Comment;

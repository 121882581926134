import React, { useEffect, useMemo, useState } from "react";

import {
	Navigate, Route, Routes, useLocation, useNavigate, useParams,
} from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";

import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

import themeDark from "assets/theme-dark";

import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

import routes from "./config/routes";
import menus from "./config/menus";

import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "context";

import { useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/pt-br";

import NotFound from "./layouts/NotFound";
import Login from "./layouts/Login";
import Sidenav from "./components/Sidenav";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";


moment.locale("pt-br");

export default function App() {
	const [controller, dispatch] = useMaterialUIController();
	const { isAuthenticated } = useSelector(state => state.auth)

	const {
		miniSidenav,
		direction,
		layout,
		openConfigurator,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const [rtlCache, setRtlCache] = useState(null);
	const { pathname } = useLocation();

	// Cache for the rtl
	useMemo(() => {
		const cacheRtl = createCache({
			key: "rtl",
			stylisPlugins: [rtlPlugin],
		});

		setRtlCache(cacheRtl);
	}, []);

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	// Change the openConfigurator state
	const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute("dir", direction);
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const getRoutes = (allRoutes) =>
		allRoutes.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse);
			}

			if (route.route) {
				return <Route exact path={route.route} element={route.component} key={route.key} />;
			}

			return null;
		});

	const configsButton = (
		<MDBox
			display="flex"
			justifyContent="center"
			alignItems="center"
			width="3.25rem"
			height="3.25rem"
			bgColor="white"
			shadow="sm"
			borderRadius="50%"
			position="fixed"
			right="2rem"
			bottom="2rem"
			zIndex={99}
			color="dark"
			sx={{ cursor: "pointer" }}
			onClick={handleConfiguratorOpen}
		>
			<Icon fontSize="small" color="inherit">
				settings
			</Icon>
		</MDBox>
	);

	return (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<CssBaseline />
			{isAuthenticated && (
				<>
					<Sidenav
						color={sidenavColor}
						brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
						brandName=""
						menus={menus}
						onMouseEnter={handleOnMouseEnter}
						onMouseLeave={handleOnMouseLeave}
					/>
					{/*<Configurator />*/}
					{/*{configsButton}*/}
				</>
			)}
			{layout === "vr" && <Configurator />}
			<Routes>
				{routes.map((pathItem) => {
					if (pathItem.path === "/entrar") {
						return <Route key={pathItem.key} path={pathItem.path} element={<SignInRouteWithRouter />} />;
					}
					return (
						<Route
							key={pathItem.key}
							path={pathItem.path}
							element={pathItem.logged ? <ProtectedRouteWithRouter>{pathItem.component}</ProtectedRouteWithRouter> : pathItem.component}
						/>
					);
				})}

				<Route path="*" element={<NotFound />} />
			</Routes>
		</ThemeProvider>
	);
}

const SignInRouteWithRouter = withRouter(SignInRoute);
const ProtectedRouteWithRouter = withRouter(ProtectedRoute);

function SignInRoute() {
	const { isAuthenticated } = useSelector(state => state.auth)

	if (isAuthenticated) {
		return <Navigate to="/" replace />;
	}
	return <Login />;
}

function ProtectedRoute({ children, ...rest }) {
	const { isAuthenticated } = useSelector(state => state.auth)

	if (!isAuthenticated) {
		return <Navigate to="/entrar" replace />;
	}

	if (React.isValidElement(children)) {
		return React.cloneElement(children, { ...rest });
	}

	return children
}



function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}